const verifyFieldsDefault = {
  action_id: '',
  uuid: '',
  sms_opt_in_subscriber: true,
  sms_opt_in_voteamerica: true,
  registered: null,
  voter_status: null,
  warnings: null,
  address: null,
  last_update_date: null,
};

const verifyFieldsReducers = (state = verifyFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'VERIFY_UPDATE_FIELDS':
      return { ...state, ...action.verifyFields };
    case 'VERIFY_RESET_FIELDS':
      return { ...verifyFieldsDefault };
    default:
      return state;
  }
};

export default verifyFieldsReducers;
