export const tagline = process.env.GATSBY_TAGLINE
  ? process.env.GATSBY_TAGLINE
  : 'Everything you need to vote';

export const nextElection = process.env.GATSBY_NEXT_GENERAL_ELECTION
  ? process.env.GATSBY_NEXT_GENERAL_ELECTION
  : '2024-11-05';

// hard-coding this to relative until we add 2024-specific fields to the Civic Data API,
// at which time we will need to update pages to pull in the specific fields, along with re-adding
// DeadlineSpecific components to row renderers
export const deadlineStyle = 'relative';

export const showTopAlert = process.env.GATSBY_SHOW_TOP_ALERT
  ? process.env.GATSBY_SHOW_TOP_ALERT === 'true'
  : false;

export const topAlertMessage = process.env.GATSBY_TOP_ALERT_MESSAGE
  ? process.env.GATSBY_TOP_ALERT_MESSAGE
  : null;

export const captchaEnabled = process.env.GATSBY_GOOGLE_RECAPTCHA_ENABLED
  ? process.env.GATSBY_GOOGLE_RECAPTCHA_ENABLED === 'true'
  : false;

export const embedCaptchaEnabled = process.env
  .GATSBY_GOOGLE_RECAPTCHA_EMBED_ENABLED
  ? captchaEnabled &&
    process.env.GATSBY_GOOGLE_RECAPTCHA_EMBED_ENABLED === 'true'
  : false;
